<template>
  <div class="video-box">
    <video
      controls
      style="width: 100%; height: 250px; object-fit: fill"
      v-cdn="props.item.url"
      crossorigin="anonymous"
      ref="video"
      disablePictureInPicture
    ></video>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps(['item']);
</script>

<style scoped>
.video-box {
  width: 100%;
  height: 250px;
  position: relative;
}
</style>
